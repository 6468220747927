"use client";

import React, { FC, use, useState } from "react";
import Image, { ImageProps } from "next/image";
import notFoundImage from "@/images/404.png";

export interface NcImageProps extends Omit<ImageProps, "alt"> {
  containerClassName?: string;
  loadingSkeleton?: boolean;
  alt?: string;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "relative",
  loadingSkeleton = false,
  alt = "nc-imgs",
  src,
  fill,
  className = "object-cover w-full h-full",
  sizes = "(max-width: 600px) 480px, 800px",
  ...args
}) => {
  const defaultContainerClass = `${containerClassName} ${
    loadingSkeleton ? "loading-skeleton" : ""
  }`;
  const [containerClass, setContainerClass] = useState(defaultContainerClass);
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <div className={containerClass}>
      {src ? (
        <Image
          className={className}
          alt={alt}
          sizes={sizes}
          {...args}
          src={imgSrc}
          fill={fill}
          onError={() => setImgSrc(notFoundImage)}
          onLoad={() =>
            loadingSkeleton &&
            setContainerClass(containerClass.replace("loading-skeleton", ""))
          }
        />
      ) : null}
    </div>
  );
};

export default NcImage;
